import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Form, Button, message, Spin, Space, Slider, Divider } from 'antd'

import axios from 'axios'

import { SessionContext } from '../../context/SessionContext'

const Parametros = () => {
    const { url, usuario } = useContext(SessionContext)
    
    const [cargando, setCargando] = useState(false)

    const onGrabarParametros = async (values) => {
        setCargando(true)

        axios({
            method: 'post',
            url: url + `/params_upd?usuario=${usuario.cUsuario}`,
            headers: {
                'Content-Type': 'text/plain'
            }, 
            data: JSON.stringify(values)
        })
        .then(res => {
            setCargando(false)
            if (res.data.success) {
                message.success('Parámetros actualizados con éxito')

            } else {
                message.error(`Error inesperado: ${res.data.message}`)
                console.error('[onGrabarParametros]', res)
            }
        })
        .catch(err => {
            setCargando(false)
            message.error(`Error inesperado: ${err}`)
            console.error('[onGrabarParametros]', err)
        })
    }

    const [form] = Form.useForm()

    useEffect(() => {
        const source = axios.CancelToken.source()
        let valores = {}

        setCargando(true)
        axios
            .post(`${url}/params`)
            .then(res => {
                setCargando(false)
                if (res.data.success) {
                    res.data.records.forEach(element => {
                        valores[element.cNombre] = Number(element.cValor)
                    })

                    form.setFieldsValue(valores)

                } else {
                    message.error(`Error inesperado: ${res.data.message}`)
                    console.error('[useEffect]', res)
                }
            })
            .catch(err => {
                setCargando(false)
                message.error(`Error inesperado: ${err}`)
                console.error('[useEffect]', err)
            })

        return () => {
            source.cancel()
        }

    }, [url, form, setCargando])

    return (
        cargando ? <Spin className="spin-cargando" size="large" tip="Actualizando datos..."/>  :
        <Fragment>
            <Divider orientation="left">Prueba de Vida</Divider>
            <Form
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: 300 }}
                layout="vertical"
                onFinish={onGrabarParametros}
            >
                <Space direction="vertical" size='middle'>
                    <Form.Item
                        name="MIN_SCORE_OK"
                        label="Coinicidencia mínima"
                        tooltip="Porcentaje mímino de la prueba de vida para considerarla válida"
                        required
                    >
                        <Slider 
                            min={0}
                            max={10000}
                            step={1000}
                            marks={{
                                2500: '25%',
                                5000: '50%',
                                7500: '75%',
                                10000: '100%'
                            }}
                            style={{margin: '0 10px', width: 400}}
                        />
                    </Form.Item>
                    <Form.Item
                        name="BLOQUEO_FALLIDOS"
                        label="Bloqueos fallidos"
                        tooltip="Cantidad de pruebas de vida fallidas antes de bloquear al beneficiario"
                        required
                    >
                        <Slider 
                            min={0}
                            max={20}
                            step={1}
                            marks={{
                                1: '1',
                                5: '5',
                                10: '10',
                                15: '15',
                                20: '20',
                            }}
                            style={{margin: '0 10px', width: 400}}
                        />
                    </Form.Item>
                    <Form.Item
                        name="FRECUENCIA_PRUEBA_VIDA"
                        label="Duración ciclo (Días)"
                        tooltip="Cantidad de días de duración de un ciclo cuya fecha de vencimiento será a los 30 días de iniciado"
                        required
                    >
                        <Slider 
                            min={30}
                            max={360}
                            step={30}
                            marks={{
                                30: '30',
                                60: '60',
                                90: '90',
                                120: '120',
                                150: '150',
                                180: '180',
                                210: '210',
                                240: '240',
                                270: '270',
                                300: '300',
                                330: '330',
                                360: '360'
                            }}
                            style={{margin: '0 10px', width: 400}}
                        />
                    </Form.Item>
                </Space>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ margin: '10px 0'}}>
                        Grabar
                    </Button>
                </Form.Item>
            </Form>
        </Fragment>
    )
}

export default Parametros