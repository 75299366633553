import React, { useState, useContext, useEffect, Fragment } from 'react'
import { Form, message, Input, Button, Row, Col, Spin, Typography } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import axios from 'axios'

import './Login.less'

import { SessionContext } from '../../context/SessionContext'


const Login = () => {
    const { url, setUsuario } = useContext(SessionContext)
    
    const [cargando, setCargando] = useState(false)

    const [form] = Form.useForm()
    const { Title } = Typography

    const onLogin = (values) => {
        setCargando(true)

        axios
            .post(`${url}/login?usuario=${values.usuario}&password=${values.password}`)
            .then(res => {
                if (res.data.success) {
                    setUsuario(res.data)
                    message.success(res.data.sexo === 'Masculino' ? `Bienvenido ${res.data.nombre} ${res.data.apellido}` : `Bienvenida ${res.data.nombre} ${res.data.apellido}`)
                } else {
                    message.error(res.data.message)
                    // message.error('Usuario / Password inválidos')
                }
                setCargando(false)
            })
            .catch(err => {
                setCargando(false)
                message.error('Error inesperado, vuelva a intentar')
                console.error('[onLogin] Error inesperado', err)
            })
    }

    useEffect(() => {
        const source = axios.CancelToken.source()
    
        return () => {
            source.cancel()
        }
    }, [])


    return (
        cargando ? <Spin className="spin-cargando" size="large" tip="Verificando datos..."/>  :
        
        <Fragment>
            <div style={{ 
                backgroundImage: 'url(/img/bg-login.jpg)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'container',
            }}>
                <Row justify="center" align="middle" style={{minHeight: '100vh'}}>
                    <Col className="formulario" align="middle" span={12} >
                        <Title level={4}>Sistema control supervivencias</Title>
                        <Form
                            form={form}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{width:300}}
                            layout="vertical"
                            onFinish={onLogin}
                            onFinishFailed={() => {message.error('Existen errores en el formulario')}}
                        >
                            <Form.Item name="usuario" label="Usuario" rules={[{ required: true, type: "email", message: 'Campo incompleto o inválido' }]}>
                                <Input placeholder="Ingrese su email registrado"/>
                            </Form.Item>   
                            <Form.Item name="password" label="Clave" rules={[{ required: true, message: 'Campo incompleto o inválido' }]}>
                                <Input.Password
                                    placeholder="Ingrese su clave de acceso..."
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button 
                                    type="primary" 
                                    htmlType="submit" 
                                    style={{
                                        margin: '8px 0',
                                        width: '100%'
                                    }}
                                >Ingresar</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
 
export default Login