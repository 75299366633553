import React, { createContext, useState } from 'react'

export const SessionContext = createContext()

const SessionProvider = (props) => {
    const [usuario, setUsuario] = useState(null)
    const [signedIn, setSignedIn] = useState(null)
    
    const url = 'https://5llh70gozb.execute-api.us-west-2.amazonaws.com'

    return (
        <SessionContext.Provider
            value= {{
                url,
                usuario,
                setUsuario,
                signedIn,
                setSignedIn
            }}
        >
            {props.children}
        </SessionContext.Provider>
    )
}

export default SessionProvider;