import React, { useEffect, useState, useCallback, useContext, Fragment } from 'react'
import NumberFormat from 'react-number-format'
import { Button, Table, message, Col, Row, Space, Tag, Tooltip, Divider, DatePicker, Slider, Select } from 'antd'
import { HistoryOutlined, EyeOutlined, UndoOutlined, EyeInvisibleOutlined, FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import ModalHistorial from './ModalHistorial'
import { Excel } from 'antd-table-saveas-excel'

import { SessionContext } from '../../context/SessionContext'
import { PruebaVidaContext } from '../../context/PruebaVidaContext'

import useColumnSearch from '../../hooks/useColumnSearch'

import { DateTime } from "luxon"
import axios from 'axios'

const PruebaVida = () => {
    const { url, usuario } = useContext(SessionContext)
    const { setBeneficiario, isModalHistorialVisible, setIsModalHistorialVisible } = useContext(PruebaVidaContext)
    
    const [cargando, setCargando] = useState(false)
    const [dataSource, setDataSource] = useState(null)
    const [actualizarDataSource, setActualizarDataSource] = useState(true)
    const [filtroRangoCoincidencia, setFiltroRangoCoincidencia] = useState([0, 100])
    const [filtroRangoFechas, setFiltroRangoFechas] = useState([])
    const [filtroMarca, setFiltroMarca] = useState(null)
    const [filtroEstado, setFiltroEstado] = useState(null)
    const [filtroRangoEdad, setFiltroRangoEdad] = useState([20, 100])
    
    const { getColumnSearchProps } = useColumnSearch()

    const exportaExcel = () => {
        const excel = new Excel()

        excel
            .addSheet('beneficiarios')
            .addColumns(columnsExcel)
            .addDataSource(dataSource)
            .saveAs('acreditados.xlsx')
    }

    const obtenerUltimasPruebas = useCallback( async () => {
        let arrDatos = []
        const strFiltroRangoFechas = filtroRangoFechas?.length === 2 ? '&fecha_inicio=' + filtroRangoFechas[0].startOf('month').format('YYYY-MM-DD') + '&fecha_termino=' + filtroRangoFechas[1].endOf('month').format('YYYY-MM-DD') : '',
            strFiltroSeguimiento = filtroMarca !== null && filtroMarca === 'seguimiento' ? '&seguimiento=1' : '',
            strFiltroEstado =  filtroEstado !== null && filtroEstado !== 0 ? '&estado=' + filtroEstado : ''
        
        setCargando(true)

        axios
            .post(`${url}/prueba_vida_ult?usuario=${usuario.cUsuario}&score_min=${filtroRangoCoincidencia[0]*100}&score_max=${filtroRangoCoincidencia[1]*100}${strFiltroRangoFechas}${strFiltroSeguimiento}&edad_min=${filtroRangoEdad[0]}&edad_max=${filtroRangoEdad[1]}${strFiltroEstado}`)
            .then(res => {
                if (res.data.success) {
                    arrDatos = res.data.records.map((rec, index) => (
                        {
                            ...rec,
                            key: DateTime.fromSQL(rec.tCreacion).toISO() + '_' + rec.pCallRenaper,
                            cBeneficiario: rec.cApellido + ', ' + rec.cNombre
                        }
                    ))
                } else {
                    message.error(`Error inesperado: ${res.data.message}`)
                    console.error('[obtenerUltimasPruebas]', res)
                }
            })
            .finally(() => {
                // console.log('[obtenerUltimasPruebas]',arrDatos)
                setCargando(false)
                setActualizarDataSource(false)
                setDataSource(arrDatos)
            })
            .catch(err => {
                setCargando(false)
                setActualizarDataSource(false)
                message.error(`Error inesperado: ${err}`)
                console.error('[obtenerUltimasPruebas]', err)
            })
        
    }, [setCargando, setDataSource, url, usuario, filtroRangoCoincidencia, filtroRangoFechas, filtroMarca, filtroRangoEdad, filtroEstado])

    const reiniciarFiltros = () => {
        setFiltroRangoCoincidencia([0,100])
        setFiltroRangoEdad([20,100])
        setFiltroRangoFechas([])
        setFiltroMarca(null)
        setFiltroEstado(null)

        setActualizarDataSource(true)
    }

    const onFiltroRangoCoinicidencia = (value) => {
        setFiltroRangoCoincidencia(value)
    }

    const onFiltroRangoEdad = (value) => {
        setFiltroRangoEdad(value)
    }

    const onFiltroRangoFechas = (fechas) => {
        // fechas = [moment, moment]
        setFiltroRangoFechas(fechas)
    }

    const onFiltroEstado = (valor) => {
        setFiltroEstado(valor)
        setActualizarDataSource(true)
    }

    const onFiltroMarca = (valor) => {
        setFiltroMarca(valor)
        setActualizarDataSource(true)
    }

    const onMarcarSeguimiento = async (row, index) => {
        const data = row.record
        const valor = data.bSeguimiento === '1' ? '0' : '1'
        
        setCargando(true)
        
        axios
            .post(`${url}/seguimiento_upd?usuario=${usuario.cUsuario}&seguimiento=${valor}&id_usuario=${data.fUsuario}`)
            .then(res => {
                if (res.data.success) {
                    message.success('Registro actualizado con éxito')

                } else {
                    message.error(`Error inesperado: ${res.data.message}`)
                    console.error('[onMarcarSeguimiento]', res)
                }
            })
            .finally(() => {
                setActualizarDataSource(true)
                setCargando(false)
            })
            .catch(err => {
                setCargando(false)
                setActualizarDataSource(false)
                message.error(`Error inesperado: ${err}`)
                console.error('[onMarcarSeguimiento]', err)
            })
    } 

    const onVerHistorial = (record) => {
        setBeneficiario(record.record)
        setIsModalHistorialVisible(true)
    }

    useEffect(() => {
        const source = axios.CancelToken.source()

        usuario && actualizarDataSource && obtenerUltimasPruebas()

        return () => {
            source.cancel()
        }
    }, [usuario, actualizarDataSource, obtenerUltimasPruebas])
    
    const columns = [
        {
            title: 'Datos Beneficiario',
            children: [
                {
                    title: 'Apellido, Nombre',
                    dataIndex: 'cBeneficiario',
                    ...getColumnSearchProps('cBeneficiario')
                },
                {
                    title: 'Clase',
                    dataIndex: 'cClase'
                },
                {
                    title: 'Edad',
                    dataIndex: 'nEdad',
                    width: 50,
                    align: 'right',
                },
                {
                    title: 'Dni',
                    dataIndex: 'cDocumento',
                    width: 120,
                    align: 'right',
                    ...getColumnSearchProps('cDocumento')
                }
            ]
        },
        {
            title: 'Último Ciclo',
            children: [
                {
                    title: 'Nro.',
                    dataIndex: 'nCiclo',
                    width: 50
                },
                {
                    title: 'Vencimiento',
                    dataIndex: 'dSiguientePrueba',
                    width: 100,
                    render: fecha => {
                        return (
                            <div>{DateTime.fromSQL(fecha).toFormat('dd/MM/yyyy')}</div>
                        )
                    }
                },
                {
                    title: 'Estado',
                    dataIndex: 'cEstado',
                    width: 200,
                    render: (text, record, index) => {
                        let color
                        switch (text) {
                            case 'PENDIENTE':
                                color ='blue'
                                break;
                            case 'APROBADO':
                                color ='lime'
                                break;
                            case 'BLOQUEADO':
                                color ='orange'
                                break;
                            case 'RECHAZADO':
                            case 'RECHAZADO VENCIDO':
                            case 'RECHAZADO AUDITORIA':
                                color ='red'
                                break;
                            case 'PENDIENTE VENCIDO':
                                color ='yellow'
                                break;
                            case 'APROBADO AUDITORIA':
                                color ='green'
                                break;
                            case 'SIN RESPUESTA':
                                color ='purple'
                                break;
                            default:
                                color ='gray'
                        }
                        return <Tag color={color} style={{margin: '5px'}}>{text}</Tag>
                    }
                }
            ],
        },
        {
            title: 'Última Prueba de Vida',
            children: [
                {
                    title: 'Fecha',
                    dataIndex: 'tCreacion',
                    width: 180,
                    render: fecha => {
                        return (
                            <div>{DateTime.fromSQL(fecha).toFormat('dd/MM/yyyy HH:mm')}hs</div>
                        )
                    }
                },
                {
                    title: 'Resultado',
                    dataIndex: 'resultado',
                    width: 100,
                    align: 'right',
                    render: (text, record, index) => {
                        let result = []
                        text === 'EXITOSO' ? result.push(<Tag color="green" style={{margin: '5px'}}>Exitoso</Tag>) : result.push(<Tag color="red" style={{margin: '5px'}}>Fallido</Tag>)
                        return result
                    }
                },
                {
                    title: 'Coincidencia',
                    dataIndex: 'nScore',
                    align: 'right',
                    width: 100,
                    onFilter: (value, record) => console.log('[onFilterCoincidencia]',value),
                    render: valor => {
                        return (
                            valor && <NumberFormat value={valor/100} displayType={'text'} decimalSeparator=',' decimalScale={2} suffix={'%'} />
                        )
                    }
                }
            ]
        },
        {
            title: 'Marca',
            dataIndex: 'situacion',
            width: 100,
            render: (valor, record) => {
                let result = []
                record.bSeguimiento === '1' && result.push(<Tag color="magenta" style={{margin: '5px'}}>Seguimiento</Tag>)
                return result
            }  
        },
        {
            title:"Acciones",
            dataIndex: 'acciones',
            width: 100,
            render: (_, record, index) => (
                <Space size="small">
                    <Tooltip placement="top" title="Ver Historial Supervivencias">
                        <Button type="default" shape="circle" onClick={() => onVerHistorial({record})} icon={<HistoryOutlined />}/>
                    </Tooltip>
                    { record.bSeguimiento === '0' ?
                        <Tooltip placement="top" title="Marcar Para Seguimiento">
                            <Button type="default" shape="circle" onClick={() => onMarcarSeguimiento({record, index})} icon ={<EyeOutlined />}/>
                        </Tooltip> :
                        <Tooltip placement="top" title="Desmarcar Seguimiento">
                            <Button type="default" shape="circle" onClick={() => onMarcarSeguimiento({record, index})} icon ={<EyeInvisibleOutlined />}/>
                        </Tooltip>
                    }
                </Space>
            )
        }
    ]

    const renderContent = (value, row, index) => {
        return {
          children: value,
          props: {},
        }
    }

    const columnsExcel = [
        {
            title: 'Beneficiario',
            dataIndex: 'cBeneficiario',
            render: renderContent
        },
        {
            title: 'Clase',
            dataIndex: 'cClase',
            render: renderContent
        },
        {
            title: 'Edad',
            dataIndex: 'nEdad',
            width: 100,
            align: 'right',
            render: renderContent
        },
        {
            title: 'Dni',
            dataIndex: 'cDocumento',
            width: 150,
            align: 'right',
            render: renderContent
        },
        {
            title: 'Nro. Ciclo',
            dataIndex: 'nCiclo',
            width: 50,
            render: renderContent
        },
        {
            title: 'Vencimiento Ciclo',
            dataIndex: 'dSiguientePrueba',
            width: 200,
            render: fecha => {
                return {
                    children: DateTime.fromSQL(fecha).toFormat('dd/MM/yyyy'),
                    props: {}
                }
            }
        },
        {
            title: 'Estado Ciclo',
            dataIndex: 'cEstado',
            width: 200,
            render: renderContent
        },
        {
            title: 'Control Auditoria',
            dataIndex: 'tAuditoria',
            width: 200,
            render: fecha => {
                return {
                    children: fecha && DateTime.fromSQL(fecha).toFormat('dd/MM/yyyy'),
                    props: {}
                }
            }
        },
        {
            title: 'Última Supervivencia',
            dataIndex: 'tCreacion',
            width: 200,
            render: (fecha, row, index) => {
                return {
                    children: fecha && DateTime.fromSQL(fecha).toFormat('dd/MM/yyyy HH:mm'),
                    props: {}
                }
            }
        },
        {
            title: 'Resultado',
            dataIndex: 'resultado',
            width: 100,
            align: 'right',
            render: renderContent
        },
        {
            title: 'Coincidencia',
            dataIndex: 'nScore',
            align: 'right',
            width: 100,
            render: renderContent
        },
        {
            title: 'Seguimiento',
            dataIndex: 'bSeguimiento',
            width: 100,
            align: 'right',
            render: (value, row, index) => {
                return {
                    children: value === '1' ? 'SI' : 'NO',
                    props: {}
                }
            }  
        },
    ]

    const marcasCoincidencia = {
        0: '0%',
        25: '25%',
        50: '50%',
        75: '75%',
        99: '100%'
    }

    const marcasEdad = {
        20: '20',
        35: '35',
        50: '50',
        65: '65',
        80: '80',
        100: '100'
    }

    const { Option } = Select
    const { RangePicker } = DatePicker

    return (
        <Fragment>
            <Row>
                <Col span={20}>
                    <Row>
                        <Col flex={1}>
                            <span>Edad</span><Slider 
                                range={true} 
                                marks={marcasEdad} 
                                step={5}
                                value={filtroRangoEdad}
                                min={20}
                                style={{
                                    // margin: '0 10px', 
                                    // width: 200
                                }}
                                onChange={(value) => onFiltroRangoEdad(value)}
                            />
                        </Col>
                        <Divider type='vertical'/>
                        <Col flex={1}>
                            <Select placeholder="Estado" 
                                // style={{ width: 200 }} 
                                style={{ width: '100%'}}
                                value={filtroEstado} 
                                onChange={onFiltroEstado}
                            >
                                <Option value={0}>Todos</Option>
                                <Option value={20}>Pendiente</Option>
                                <Option value={40}>Aprobado</Option>
                                <Option value={60}>Bloqueado</Option>
                                <Option value={80}>Rechazado</Option>
                                <Option value={90}>Vencido</Option>
                                <Option value={100}>Rechazado (Vencido)</Option>
                                <Option value={120}>Rechazado (Auditoria)</Option>
                                <Option value={140}>Aprobado (Auditoria)</Option>
                                <Option value={160}>Sin Respuesta</Option>
                            </Select>
                        </Col>
                        <Divider type='vertical'/>
                        <Col>
                            <RangePicker 
                                picker="month" 
                                style={{ width: 200, margin:'0 10px' }}
                                format={'MM/YYYY'}
                                disabledDate={(currentDate) => {
                                    return DateTime.local() < currentDate 
                                }}
                                placeholder={["Ult. Prueba Inicio", "Ult. Prueba Fin"]}
                                value={filtroRangoFechas}
                                onChange={(date, strDate) => onFiltroRangoFechas(date)}
                            />
                        </Col>
                        <Col flex={1}>
                            <span>Coincidencia</span><Slider 
                                range={true} 
                                marks={marcasCoincidencia} 
                                step={10}
                                value={filtroRangoCoincidencia}
                                style={{
                                    // margin: '0 10px', 
                                    // width: 300
                                }}
                                onChange={(value) => onFiltroRangoCoinicidencia(value)}
                            />
                        </Col>
                        <Divider type='vertical'/>
                        <Col flex={1}>
                            <Select placeholder="Marca" 
                                // style={{ width: 250 }} 
                                style={{ width: '100%'}}
                                value={filtroMarca} 
                                onChange={onFiltroMarca}
                            >
                                <Option value={'todos'}>Todos</Option>
                                <Option value={'seguimiento'}>Sólo Marcardos Seguimiento</Option>
                            </Select>
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Row justify="end" align="middle">
                        <Space size="small">
                            <Tooltip placement="top" title="Aplicar Filtros">
                                <Button 
                                    shape="circle"
                                    type='primary'
                                    icon={<FilterOutlined />}
                                    onClick={() => setActualizarDataSource(true)}
                                />
                            </Tooltip>
                            <Tooltip placement="top" title="Reiniciar Filtros">
                                <Button
                                    shape="circle"
                                    icon={<UndoOutlined />}
                                    onClick={reiniciarFiltros}
                                    style={{ background: 'transparent', margin: '0 10px' }}
                                />
                            </Tooltip>
                            <Tooltip placement='top' title='Exportar listado a Excel'>
                                <Button
                                    shape="circle"
                                    icon={<FileExcelOutlined />}
                                    onClick={exportaExcel}
                                    style={{ background: 'transparent', margin: '0 10px' }}
                                />
                            </Tooltip>
                        </Space>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Table 
                    columns={columns}
                    dataSource={dataSource}
                    rowKey="pCallRenaper"
                    loading={cargando}
                    bordered
                    style={{ width: '100%', height: '100%', margin: '20px 0' }}
                />
            </Row>

            { isModalHistorialVisible && <ModalHistorial/> } 

        </Fragment>
    )
};

export default PruebaVida;
