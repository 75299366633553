import React, { useContext } from 'react'
import { NavLink } from "react-router-dom"

import { Row, Col, Menu, Typography, Button } from 'antd'
import { PoweroffOutlined, UnorderedListOutlined, DashboardOutlined, ControlOutlined, AuditOutlined, LockOutlined } from '@ant-design/icons'

import { SessionContext } from '../../context/SessionContext'

// const { SubMenu } = Menu;
const { Text } = Typography;

const MainHeader = () => {
    const { usuario, setUsuario} = useContext(SessionContext)

    const salir = () => {
        window.localStorage.removeItem('usuario')
        setUsuario(null)
    }

    return (  
        <Row align="middle">
            <Col span={14}>
                <Menu mode="horizontal" style={{ background: 'transparent'}}>
                    <Menu.Item key='prueba_vida' icon={<UnorderedListOutlined />}>
                        <NavLink to="/">Listado Acreditados</NavLink>
                    </Menu.Item>
                    <Menu.Item key='auditoria' icon={<AuditOutlined />}>
                        <NavLink to="/auditoria">Auditoría</NavLink>
                    </Menu.Item>
                    <Menu.Item key="bloqueados" icon={<LockOutlined />}>
                        <NavLink to="/bloqueados">Beneficiarios Bloqueados</NavLink>
                    </Menu.Item>
                    <Menu.Item key="dashboard"icon={<DashboardOutlined />}>
                        <NavLink to="/indicadores">Indicadores</NavLink>
                    </Menu.Item>
                    <Menu.Item key="parametros" icon={<ControlOutlined />}>
                        <NavLink to="/parametros">Parámetros</NavLink>
                    </Menu.Item>
                </Menu>
            </Col>
            <Col span={10}>
                <Row justify="end" align="middle">
                    { usuario && 
                        <Text 
                            style={{ 'fontSize': '12px' }}>
                                {usuario.nombre + ' ' + usuario.apellido}
                        </Text> }
                    <Button
                        shape="circle"
                        icon={<PoweroffOutlined />}
                        onClick={salir}
                        style={{ background: 'transparent', margin: '0 10px' }}
                    />
                </Row>
            </Col>
        </Row>
    )
}
 
export default MainHeader