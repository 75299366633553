import React, { Fragment, useContext, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Modal, Button, message, Col, Row, Image, Empty, Typography, Card, Timeline, Spin, Skeleton, Affix, Tag, Space, Descriptions, Select, Divider } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone, MinusCircleTwoTone, StopTwoTone, QuestionCircleTwoTone } from '@ant-design/icons'

import './ModalHistorial.less'

import { PruebaVidaContext } from '../../context/PruebaVidaContext'
import { SessionContext } from '../../context/SessionContext'

import { DateTime } from "luxon"
import axios from 'axios'

const ModalHistorial = () => {
    const { beneficiario, setBeneficiario, isModalHistorialVisible, setIsModalHistorialVisible } = useContext(PruebaVidaContext)
    const { url, usuario } = useContext(SessionContext)

    const [cargando, setCargando] = useState(false)
    const [cargandoImagen, setCargandoImagen] = useState(false)
    const [ciclos, setCiclos] = useState(null)
    const [historial, setHistorial] = useState(null)
    const [registro, setRegistro] = useState(null)

    const { Title } = Typography

    const obtenerHistorial = ciclo => {
        let arrDatos = []
        axios
            .post(`${url}/ciclo/hist?usuario=${usuario.cUsuario}&id_usuario=${beneficiario.fUsuario}&numero_ciclo=${ciclo}`)
            .then(res => {
                if (res.data.success) {
                    arrDatos = res.data.records.map((item, index) => (
                        {
                            ...item,
                            tFechaEvento: item.tCreacionLlamada ? item.tCreacionLlamada : item.tCambioEstado,
                            key: index
                        }
                    ))

                    setHistorial(arrDatos)
                } else {
                    message.error(`Error inesperado: ${res.data.message}`)
                    console.error('[obtenerHistorial]', res.data.message)
                }
            })
            .catch(err => {
                message.error(`Error inesperado: ${err}`)
                console.error('[obtenerHistorial]', err)
            })
    }

    const onCerrar = () => {
        setIsModalHistorialVisible(false);
    }

    const onCicloChange = value => {
        obtenerHistorial(value)
    }

    const onRegistroClick = (record) => {
        setRegistro(null) 
        
        setRegistro(record)

        if (record.imagenes && record.imagenes.length > 0) {
            setCargandoImagen(true)
            setTimeout(() => {
                setCargandoImagen(false)
            }, 5000)
        }
    }

    useEffect(() => {
        const source = axios.CancelToken.source()

        const obtenerCiclos = () => {
            let arrDatos = []
            setCargando(true)

            axios
                .post(`${url}/ciclo/lista?usuario=${usuario.cUsuario}&id_usuario=${beneficiario.fUsuario}`)
                .then(res => {
                    setCargando(false)
                    if (res.data.success) {
                        arrDatos = res.data.records.map((item, index) => (
                            {
                                ...item,
                                key: item.pCiclo
                            }
                        ))
    
                        setCiclos(arrDatos)
                    } else {
                        message.error(`Error inesperado: ${res.data.message}`)
                        console.error('[obtenerCiclos]', res.data.message)
                    }
                })
                .catch(err => {
                    setCargando(false)
                    message.error(`Error inesperado: ${err}`)
                    console.error('[obtenerCiclos]', err)
                })
        }

        beneficiario && obtenerCiclos()
        
        return () => {
            setRegistro(null)
            setBeneficiario(null)
            source.cancel()
        }
    }, [url, usuario, beneficiario, setBeneficiario])

    const { Option } = Select

    return (
        <Modal
            maskClosable={true}
            style={{ 
                top: 20
            }}
            bodyStyle={{
                height: 700
            }}
            visible={isModalHistorialVisible}
            title={(`Historial Pruebas de Vida`)}
            onOk={onCerrar}
            onCancel={onCerrar}
            width={1500}
            footer={[
                <Button
                    type="primary"
                    key="cerrar"
                    onClick={onCerrar}
                >
                    Cerrar
                </Button>
            ]}
        >
            { cargando ? 
            <div className="spin-wrapper">
                <Spin className="spin-cargando" size="large" tip="Cargando datos..."/>
            </div> :
                <Fragment>
                    <Row>
                        { beneficiario && <Title level={3}>{beneficiario.cApellido}, {beneficiario.cNombre} ({beneficiario.nEdad} años)</Title> }
                    </Row> 
                    <Row>
                        <Col span={8}>
                            <Row>
                                { ciclos && 
                                    <Select style={{width:400, padding: '10px 0px'}} placeholder='Seleccione ciclo...' onChange={onCicloChange}>
                                        {
                                            ciclos.map(ciclo => (
                                                <Option key={ciclo.nCiclo} value={ciclo.nCiclo}>Vto. {DateTime.fromSQL(ciclo.dSiguientePrueba).toFormat('dd/MM/yyyy')} ({ciclo.cEstado})</Option>
                                            ))
                                        }
                                    </Select>
                                } 
                            </Row>
                            <Row>
                                { historial && 
                                    <Affix>
                                        <div style={{ overflow: 'auto', padding: '8px 24px', height: '650px'}}>
                                            <Timeline>
                                                { historial.map(item => {
                                                    let icon
                                                    switch (item.cEstado) {
                                                        case 'PENDIENTE':
                                                            icon = <MinusCircleTwoTone twoToneColor='blue'/>
                                                            break;
                                                        case 'APROBADO':
                                                            icon = <CheckCircleTwoTone twoToneColor='lime'/>
                                                            break;
                                                        case 'BLOQUEADO':
                                                            icon = <StopTwoTone twoToneColor='orange'/>
                                                            break;
                                                        case 'RECHAZADO':
                                                        case 'RECHAZADO VENCIDO':
                                                        case 'RECHAZADO AUDITORIA':
                                                            icon = <CloseCircleTwoTone twoToneColor='red'/>
                                                            break;
                                                        case 'PENDIENTE VENCIDO':
                                                            icon = <MinusCircleTwoTone twoToneColor='yellow'/>
                                                            break;
                                                        case 'APROBADO AUDITORIA':
                                                            icon = <CheckCircleTwoTone twoToneColor='green'/>
                                                            break;
                                                        case 'SIN RESPUESTA':
                                                            icon = <CloseCircleTwoTone twoToneColor='pruple'/>
                                                            break;
                                                        default:
                                                            icon = <QuestionCircleTwoTone  twoToneColor='grey'/>
                                                    }

                                                    return <Timeline.Item
                                                        dot={ <Button shape="circle" size="small" icon={icon} onClick={() => onRegistroClick(item)}/> }
                                                    > {item.cEstado} {DateTime.fromSQL(item.tFechaEvento).toFormat('dd/MM/yyyy HH:mm')}hs
                                                    </Timeline.Item>
                                                })}
                                            </Timeline>
                                        </div>
                                    </Affix>  
                                }
                            </Row>
                        </Col>
                        <Col span={16}>
                            { cargandoImagen ?                         
                                <div className="spin-wrapper">
                                    <Spin className="spin-cargando" size="large" tip="Cargando imágenes..."/>
                                </div> 
                                : !registro ? 
                                    <Empty description='Click en un estado para ver detalles'/>
                                :
                                    <Card
                                        title={
                                            <Space size={5}>
                                                <Title level={4} style={{
                                                    'margin-top': '7px'
                                                }}>Estado {registro.cEstado}</Title>
                                                {registro.resultado === 'EXITOSO' && <Tag color="green" style={{margin: '10px'}}>Exitoso</Tag> }
                                                {registro.resultado === 'FALLIDO' && <Tag color="red" style={{margin: '10px'}}>Fallido</Tag>}
                                            </Space>
                                        }
                                        style={{ 
                                            margin: '5px',
                                            height: 600
                                        }}
                                        headStyle={{background: 'rgb(250 250 250)'}}
                                    >
                                        {
                                            registro.imagenes && registro.imagenes.length > 0 && <Row>
                                                <Image.PreviewGroup>
                                                    <Space size={10}>
                                                        {registro.imagenes.map(item => (
                                                            <Image
                                                                width={200}
                                                                src={`${url}/renaper_image?id_llamada=${registro.pCallRenaper}&perfil=${item.cPerfil}`}
                                                                placeholder={<Skeleton.Image />}
                                                            />
                                                        ))}
                                                    </Space>
                                                </Image.PreviewGroup>                                       
                                            </Row>
                                        }
                                        <Row>
                                            <Descriptions
                                                bordered
                                                size='small'
                                            >
                                                <Descriptions.Item label='Fecha'>{DateTime.fromSQL(registro.tCambioEstado).toFormat('dd/MM/yyyy HH:mm')}hs</Descriptions.Item>
                                                { registro.nScore && <Descriptions.Item label='Coincidencia'><NumberFormat value={registro.nScore/100} displayType={'text'} decimalSeparator=',' decimalScale={2} suffix={'%'} /></Descriptions.Item>}
                                            </Descriptions>
                                        </Row>
                                        <Divider/>
                                        {
                                            registro.tAuditoria && <Descriptions
                                                bordered
                                                size='small'
                                            >
                                                <Descriptions.Item label='Fecha Auditoria'>{DateTime.fromSQL(registro.tAuditoria).toFormat('dd/MM/yyyy HH:mm')}hs</Descriptions.Item>
                                                <Descriptions.Item label='Usuario'>{registro.cUsuarioAuditor}</Descriptions.Item>
                                                <Descriptions.Item label='Acción'>{registro.cAccionAuditoria}</Descriptions.Item>
                                                <Descriptions.Item label='Comentario'>{registro.cComentario}</Descriptions.Item>
                                            </Descriptions>
                                        }
                                    </Card>
                            }
                        </Col>
                    </Row>
                </Fragment>            
            }
        </Modal>
    )
}

export default ModalHistorial
