import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import './index.less';
import App from './App';

import SessionProvider from './context/SessionContext'

ReactDOM.render(
  <SessionProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SessionProvider>,
  document.getElementById('root')
);