import React, { useState, useEffect, useContext, Fragment, useReducer } from 'react'

import './Dashboard.less'

import { SessionContext } from '../../context/SessionContext'

import axios from 'axios'
import { DateTime } from "luxon"

import { Spin, Divider, Row, Col, Space, Card, message, Statistic, Tooltip, DatePicker, Slider, Button} from 'antd'
import { UndoOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons'
import { Gauge, Pie, Bar } from '@ant-design/plots'

const { Meta } = Card;

// State Inicial
const initialState = {
  actualizarDatos: true,
  totalAcreditados: 0,
  totalBeneficiarios: 0,
  cantSupervivenciasExitosas: 0,
  cantSupervivenciasFallidas: 0,
  cant1FalloExitosas: 0,
  cant2FallosExitosas: 0,
  cant3MasFallosExitosas: 0,
  cant1FalloFallidas: 0,
  cant2FallosFallidas: 0,
  cant3MasFallosFallidas: 0,
  porcentualAcreditados: 0,
  listRangosEdadFallidas: [],
  edadMediaFallidos: 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'RECARGAR':
      return {
        actualizarDatos: true
      }

    case 'UPDATEINDICADORES':
      const porcentualAcreditados = action.valores.totalAcreditados > 0 && action.valores.totalBeneficiarios > 0 ?  (action.valores.totalAcreditados / action.valores.totalBeneficiarios).toFixed(4) : 0

      return {
        ...action.valores,
        porcentualAcreditados: porcentualAcreditados,
        actualizarDatos: false
      }
    
    default:
      return state
  }
}

const Dashboard = () => {
  const { url, usuario, setSignedIn } = useContext(SessionContext)

  const [cargando, setCargando] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState)
  
  const [filtroRangoFechas, setFiltroRangoFechas] = useState([])
  const [filtroRangoEdad, setFiltroRangoEdad] = useState([20, 100])

  useEffect(() => {
    const source = axios.CancelToken.source()

    const obtenerDatos = async() => {
      
      // Llamada utiliza token por lo que debe verificarse si aun es valido
      if (DateTime.now() > DateTime.fromSQL(usuario.expires_date)) {
        // token vencido => login
        setSignedIn(false)
        return {
          success: false
        }
      }
  
      setCargando(true)
      let indicadores = {}

      await axios
        .get(`${url}/indicadores?token=${usuario.access_token}`)
        .then(res => {
          if (res.data.success) {
            const records = res.data.records[0].records
  
            records.forEach(ind => {
              switch (ind.descripcion) {
                case 'Padrón de Beneficiarios':
                  indicadores = {
                    ...indicadores,
                    totalBeneficiarios: ind.valor
                  }
                  break
                case 'Beneficiarios Acreditados':
                  indicadores = {
                    ...indicadores,
                    totalAcreditados: ind.valor
                  }
                  break
                case 'Beneficiarios Validados OK':
                  indicadores = {
                    ...indicadores,
                    cantSupervivenciasExitosas: ind.valor
                  }
                  break
                case 'Beneficiarios Fallidos':
                  indicadores = {
                    ...indicadores,
                    cantSupervivenciasFallidas: ind.valor
                  }
                  break
                case 'Beneficiarios Validados OK con 1 Fallido':
                  indicadores = {
                    ...indicadores,
                    cant1FalloExitosas: ind.valor
                  }
                  break
                case 'Beneficiarios Validados OK con 2 Fallidos':
                  indicadores = {
                    ...indicadores,
                    cant2FallosExitosas: ind.valor
                  }
                  break
                case 'Beneficiarios Validados OK con 3 o mas Fallidos':
                  indicadores = {
                    ...indicadores,
                    cant3MasFallosExitosas: ind.valor
                  }
                  break
                case 'Beneficiarios Fallidos 1 Intento / Total de Fallidos':
                  indicadores = {
                    ...indicadores,
                    cant1FalloFallidas: ind.valor
                  }
                  break
                case 'Beneficiarios Fallidos 2 Intentos / Total de Fallidos':
                  indicadores = {
                    ...indicadores,
                    cant2FallosFallidas: ind.valor
                  }
                  break
                case 'Beneficiarios Fallidos 3 o más Intentos / Total de Fallidos':
                  indicadores = {
                    ...indicadores,
                    cant3MasFallosFallidas: ind.valor
                  }
                  break
                case 'Intervalo Modal Edad de Beneficiarios Fallidos':
                  indicadores = {
                    ...indicadores,
                    listRangosEdadFallidas: ind.valor
                  }
                  break
                
                  case 'Medía Edad de Beneficiarios Fallidos':
                    indicadores = {
                      ...indicadores,
                      edadMediaFallidos: parseFloat(ind.valor).toFixed(2)
                    }
                  break

                default:
                  break
              }
            })
            
            dispatch({type: 'UPDATEINDICADORES', valores: indicadores})

          } else {
            message.error(`Error inesperado: ${res.data.message}`)
            console.error('[obtenerDatos]', res)
          }
        })
        .finally(() => {
          setCargando(false)
        })
        .catch(err => {
          message.error(`Error inesperado: ${err}`)
          console.error('[obtenerDatos]', err)
          setCargando(false)
        })
    }

    usuario && state.actualizarDatos && obtenerDatos()

    return () => source.cancel()
  }, [usuario, state.actualizarDatos, setSignedIn, url, setCargando])

  const configAcreditados = {
    style: {
      height: 250
    },
    percent: state.porcentualAcreditados,
    range: {
      color: '#30BF78',
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    axis: {
      label: {
        formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      content: {
        formatter: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
        style: {
          color: 'rgba(0,0,0,0.65)',
          fontSize: 30,
        },
      }
    }
  }

  const configSupervivenciaTotal = {
    appendPadding: 0,
    data: [
      {
        type: 'Sin Acreditar',
        value: state.totalBeneficiarios - state.totalAcreditados
      },
      {
        type: 'Fallidas',
        value: state.cantSupervivenciasFallidas
      },
      {
        type: 'Exitosas',
        value: state.cantSupervivenciasExitosas
      },
      {
        type: 'Pendientes',
        value: state.totalAcreditados - state.cantSupervivenciasFallidas - state.cantSupervivenciasExitosas
      }
    ],
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }

  const configSupervivenciaAcreditados = {
    appendPadding: 0,
    data: [
      {
        type: 'Fallidas',
        value: state.cantSupervivenciasFallidas
      },
      {
        type: 'Exitosas',
        value: state.cantSupervivenciasExitosas
      },
      {
        type: 'Pendientes',
        value: state.totalAcreditados - state.cantSupervivenciasFallidas - state.cantSupervivenciasExitosas
      }
    ],
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }

  const configSupervivenciaFallosExitosos = {
    appendPadding: 5,
    data: [
      {
        type: 'Sin fallos',
        value: state.cantSupervivenciasExitosas - state.cant1FalloExitosas - state.cant2FallosExitosas - state.cant3MasFallosExitosas
      },
      {
        type: '1 fallido',
        value: state.cant1FalloExitosas
      },
      {
        type: '2 fallidos',
        value: state.cant2FallosExitosas
      },
      {
        type: '3 o más fallidos',
        value: state.cant3MasFallosExitosas
      }
    ],
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }

  const configSupervivenciaFallosFallidas = {
    appendPadding: 5,
    data: [
      {
        type: '1 fallido',
        value: state.cant1FalloFallidas
      },
      {
        type: '2 fallidos',
        value: state.cant2FallosFallidas
      },
      {
        type: '3 o más fallidos',
        value: state.cant3MasFallosFallidas
      }
    ],
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }

  const configRangosEdadFallidas = {
    data: state.listRangosEdadFallidas,
    xField: 'nFallidos',
    yField: 'nEdad',
    seriesField: 'nEdad',
    legend: false,
    color: ({ type }) => {
      return type === '30' ? '#f44336' : '#ff7875'
    }
  }

  const marcasEdad = {
    20: '20',
    35: '35',
    50: '50',
    65: '65',
    80: '80',
    100: '100'
  }

  const { RangePicker } = DatePicker

  const reiniciarFiltros = () => {
    setFiltroRangoEdad([20,100])
    setFiltroRangoFechas([])
    // dispatch({type:'UPDATEINDICADORES'})
  }

  const onFiltroRangoEdad = (value) => {
    setFiltroRangoEdad(value)
  }

  const onFiltroRangoFechas = (fechas) => {
    // fechas = [moment, moment]
    setFiltroRangoFechas(fechas)
  }

  return (
    cargando ? <Spin className="spin-cargando" size="large" tip="Actualizando datos..."/>  : 
    <Fragment>
      {/* TODO: Definicion de filtros requeridos para indicadores */}
      <Row gutter={[0, 48]}>
        <Col span={22}>
          {/* <Space size="small">
            <RangePicker 
                picker="month" 
                placeholder={["Mes Inicial", "Mes Final"]}
                value={filtroRangoFechas}
                onChange={(date, strDate) => onFiltroRangoFechas(date)}
            />
            <span>Edad</span><Slider 
                range={true} 
                marks={marcasEdad} 
                step={5}
                value={filtroRangoEdad}
                min={20}
                style={{margin: '0 10px', width: 200}}
                onChange={(value) => onFiltroRangoEdad(value)}
            />
          </Space> */}
        </Col>
        <Col span={2}>
            <Row justify="end" align="middle">
                <Space size="small">
                    {/* <Tooltip placement="top" title="Aplicar Filtros">
                      <Button 
                          shape="circle"
                          type='primary'
                          icon={<FilterOutlined />}
                          onClick={() => setActualizarDatos(true)}
                      />
                    </Tooltip>
                    <Tooltip placement="top" title="Reiniciar Filtros">
                      <Button
                          shape="circle"
                          icon={<UndoOutlined />}
                          onClick={reiniciarFiltros}
                          style={{ background: 'transparent', margin: '0 10px' }}
                      />
                    </Tooltip> */}
                    <Tooltip placement="top" title="Actualizar Datos">
                      <Button
                        shape="circle"
                        icon={<ReloadOutlined />}
                        onClick={() => {
                          dispatch({type:'RECARGAR'})
                        }}
                        style={{ background: 'transparent', margin: '0 10px' }}
                      />
                    </Tooltip>
                </Space>
            </Row>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={[16,24]} justify='start' >
        <Col span={4}>
          <Card>
            <Statistic
                title="Total acreditados"
                value={state.totalAcreditados}
                precision={0}
                valueStyle={{ color: '#40a9ff' }}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
                title="Total supervivencias exitosas"
                value={state.cantSupervivenciasExitosas}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
                title="Total supervivencias fallidas"
                value={state.cantSupervivenciasFallidas}
                precision={0}
                valueStyle={{ color: '#f44336' }}
                // prefix={<ArrowUpOutlined />}
                // suffix="%"
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
                title="Promedio edad fallidas"
                value={state.edadMediaFallidos}
                precision={1}
                valueStyle={{ color: '#f44336' }}
                suffix={'años'}
            />
          </Card>
        </Col>
      </Row>

      <Divider orientation="left">Supervivencias Realizadas</Divider>
      <Space direction="horizontal" size='middle' align='top'>
        <Card 
          title="Acreditados"
          style={{
            width: 300
          }}
          bodyStyle={{
            padding: 0
          }}
        >
          <Gauge {...configAcreditados} />
          <Meta
            style={{
              padding: 5
            }}
            description={`${state.totalBeneficiarios} total beneficiarios`}
          />
        </Card>
        <Card 
          title="Resultados"
          style={{
            width: 400
          }}
          bodyStyle={{
            padding: 0
          }}
        >
          <Pie {...configSupervivenciaTotal} />
        </Card>
      </Space>

      <Divider orientation="left">Fallidos</Divider>
      <Space direction="horizontal" size='middle' align='top'>
        <Card 
          title="Fallidos / Supervivencias Exitosas"
          style={{
            width: 400
          }}
          bodyStyle={{
            padding: 0
          }}
        >
          <Pie {...configSupervivenciaFallosExitosos} />
        </Card>
        <Card 
          title="Fallidos / Supervivencias Fallidas"
          style={{
            width: 400
          }}
          bodyStyle={{
            padding: 0
          }}
        >
          <Pie {...configSupervivenciaFallosFallidas} />
        </Card>
        <Card 
          title="Rangos Etarios"
          style={{
            width: 400
          }}
          bodyStyle={{
            padding: 0
          }}
        >
          <Bar {...configRangosEdadFallidas} />
        </Card>
      </Space>
    </Fragment>
  )
}
 
export default Dashboard