import React, { useEffect, useContext, useState, useCallback, Fragment } from 'react'
import { Table, Tag, message, Spin, Form, Radio, Input, DatePicker, Button, Modal, Tooltip, Divider, Row, Col, Space } from 'antd'
import { ExclamationCircleOutlined, UndoOutlined, FilterOutlined} from '@ant-design/icons'

import { SessionContext } from '../../context/SessionContext'

import useColumnSearch from '../../hooks/useColumnSearch'

import { DateTime } from "luxon"
import axios from 'axios'

const Bloqueados = () => {
    const { url, usuario } = useContext(SessionContext)

    const [cargando, setCargando] = useState(false)
    const [dataSource, setDataSource] = useState(null)
    const [actualizarDataSource, setActualizarDataSource] = useState(true)
    const [listaBeneficiarios, setListaBeneficiarios] = useState([])
    const [filtroRangoVencimiento, setFiltroRangoVencimiento] = useState([])
    const [accion, setAccion] = useState('desbloquea')

    const actualizarBeneficiarios = async valores => {
        const strComentario = form.getFieldValue('motivo') ? `&comentario=${form.getFieldValue('motivo')}` : '',
            strFechaVto = form.getFieldValue('vencimiento') ? `&fecha_prueba=${form.getFieldValue('vencimiento').format('YYYY-MM-DD')}` : '',
            usuarios = listaBeneficiarios.length > 0 ? JSON.parse(listaBeneficiarios) : ''

        setCargando(true)

        axios 
            .post(`${url}/renaper/audit?usuario=${usuario.cUsuario}&usuarios=${usuarios}&accion=${accion}${strComentario}${strFechaVto}`)
            .then( res => {
                if (res.data.success) {
                    message.success('Actualización realizada con éxito')
                    setActualizarDataSource(true)
                } else {
                    message.error(`Error inesperado: ${res.data.message}`)
                    console.error('[actualizarBeneficiarios]', res)
                }
            })
            .finally(() => {
                setCargando(false)
                form.resetFields()
            })
            .catch(err => {
                setCargando(false)
                message.error(`Error inesperado: ${err}`)
                console.error('[actualizarBeneficiarios]', err)
            })
    } 

    const obtenerBeneficiariosBloqueados = useCallback( 
        async() => {
            let arrDatos = []

            setCargando(true)

            axios 
                // TODO: Agregar rango de vencimiento
                .post(`${url}/usuario?usuario=${usuario.cUsuario}&bloqueado=1`)
                .then( res => {
                    if (res.data.success) {
                        arrDatos = res.data.records.map((rec, index) => (
                            {
                                ...rec,
                                key: rec.fUsuario,
                                cBeneficiario: rec.cApellido + ', ' + rec.cNombre
                            }
                        ))
                    } else {
                        message.error(`Error inesperado: ${res.data.message}`)
                        console.error('[obtenerUltimasPruebas]', res)
                    }
                })
                .finally(() => {
                    setCargando(false)
                    setActualizarDataSource(false)
                    setDataSource(arrDatos)
                })
                .catch(err => {
                    setCargando(false)
                    setActualizarDataSource(false)
                    message.error(`Error inesperado: ${err}`)
                    console.error('[obtenerUltimasPruebas]', err)
                })
        }, 
        [setCargando, setDataSource, url, usuario]
    )

    const reiniciarFiltros = () => {
        setFiltroRangoVencimiento([])
        setActualizarDataSource(true)
    }

    const onAccionChange = e => {
        setAccion(e.target.value)
    }

    const onCancelar = () => {
    }

    const onGrabar = values => {
        if (listaBeneficiarios.length === 0) {
            message.error('Debe seleccionar al menos un beneficiario')
            return
        }

        if (values.accion === 'bajar') {
            confirm({
                title: 'Atención! ¿Desea dar de baja de forma definitiva los beneficiarios seleccionados?',
                icon: <ExclamationCircleOutlined />,
                content: 'Esta acción no podrá revertirse',
                onOk() {
                    actualizarBeneficiarios(values)
                },
                onCancel() {
                    message.info('Acción cancelada')
                },
            })
        } else {
            actualizarBeneficiarios(values)
        }
    }

    const onFiltroRangoVencimiento = (fechas) => {
        // fechas = [moment, moment]
        setFiltroRangoVencimiento(fechas)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setListaBeneficiarios(selectedRowKeys)
            console.log(listaBeneficiarios)
        }
    }
      
    const [form] = Form.useForm()
    const { TextArea } = Input
    const { confirm } = Modal
    const { getColumnSearchProps } = useColumnSearch()

    useEffect(() => {
        const source = axios.CancelToken.source()
    
        usuario && actualizarDataSource && obtenerBeneficiariosBloqueados()

        return () => {
            source.cancel()
        }
    }, [usuario, actualizarDataSource, obtenerBeneficiariosBloqueados])
    
    const columns = [
        {
            title: 'Fecha Bloqueo',
            dataIndex: 'tBloqueoPrueba',
            width: 200,
            render: fecha => {
                return (
                    fecha && <div>{DateTime.fromSQL(fecha).toFormat('dd/MM/yyyy')}</div>
                )
            }
        },
        {
            title: 'Beneficiario',
            dataIndex: 'cBeneficiario',
            ...getColumnSearchProps('cBeneficiario')
        },
        {
            title: 'Estado Último Ciclo',
            dataIndex: 'cEstado',
            render: estado => {
                return (
                    <Tag color={estado === 'BLOQUEADO' ? 'orange' :'red'} key={estado}>
                        {estado}
                    </Tag>
                )
            }
        },
        {
            title: 'Vencimiento',
            dataIndex: 'dSiguientePrueba',
            width: 200,
            render: fecha => {
                return (
                    <div>{DateTime.fromSQL(fecha).toFormat('dd/MM/yyyy')}</div>
                )
            }
        },
    ]

    const { RangePicker } = DatePicker

    return ( 
        cargando ? <Spin className="spin-cargando" size="large" tip="Actualizando datos..."/>  :    
        <Fragment>
            <Row gutter={[8,16]}>
                <Col span={22}>
                    <Space size="small">
                        <RangePicker 
                            // picker="month" 
                            placeholder={["Vto. inicio", "Vto. fin"]}
                            value={filtroRangoVencimiento}
                            onChange={(date, strDate) => onFiltroRangoVencimiento(date)}
                        />
                    </Space>
                </Col>
                <Col span={2}>
                    <Row justify="end" align="middle">
                        <Space size="small">
                            <Tooltip placement="top" title="Aplicar Filtros">
                                <Button 
                                    shape="circle"
                                    type='primary'
                                    icon={<FilterOutlined />}
                                    onClick={() => setActualizarDataSource(true)}
                                />
                            </Tooltip>
                            <Tooltip placement="top" title="Reiniciar Filtros">
                                <Button
                                    shape="circle"
                                    icon={<UndoOutlined />}
                                    onClick={reiniciarFiltros}
                                    style={{ background: 'transparent', margin: '0 10px' }}
                                />
                            </Tooltip>
                        </Space>
                    </Row>
                </Col>
            </Row>
            <Divider/>
            <Row gutter={[8,16]} justify='start'>
                <Col span={16}>
                    <Table 
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={dataSource}
                        bordered
                    />
                </Col>
                <Col span={8}>
                    <Form
                        form={form}
                        name='frmDesbloqueo'
                        labelCol={{ span: 24 }}
                        layout="vertical"
                        requiredMark={'optional'}
                        onFinish={onGrabar}
                        onFinishFailed={() => (message.error('Se han detectado errores por favor verificar'))}
                        initialValues={{ accion: accion }}
                    >
                        <Form.Item 
                            required
                            tooltip="Se aplicará a todos los beneficiarios seleccionados"
                            name={'accion'} 
                            label="Acción"
                        >
                            <Radio.Group 
                                onChange={onAccionChange}
                                value={accion}
                                optionType="button"
                            >
                                <Radio.Button value="desbloquea">Desbloquear</Radio.Button>
                                <Radio.Button value="aprueba">Desbloquear y aprobar prueba de vida</Radio.Button>
                                <Radio.Button value="baja">Baja definitiva</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item 
                            label="Motivo"
                            name="motivo"
                        >
                            <TextArea rows={4} maxLength={1000} />
                        </Form.Item>
                        <Form.Item
                            label="Vencimiento"
                            name="vencimiento"
                            hidden={accion!=='desbloquea'}
                        >
                            <DatePicker 
                                format={'DD/MM/YYYY'}
                                disabledDate={(currentDate) => {
                                    return DateTime.local().plus({ days: -1 }) >= currentDate 
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button onClick={onCancelar}>Cancelar</Button>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                style={{
                                    margin: '0 8px',
                                }}
                            >Actualizar</Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Fragment>
    )
}
 
export default Bloqueados;