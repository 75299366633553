import React, { useState, useContext, useEffect, useReducer, Fragment } from 'react'
import NumberFormat from 'react-number-format'

import './Auditoria.less'

import { Button, Row, Col, Slider, Space, Tooltip, DatePicker, Divider, Select, InputNumber, Empty, Spin, message, Statistic, Card, Progress, Typography, Image, Skeleton, Descriptions, Checkbox, Input, Form } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, RightCircleOutlined } from '@ant-design/icons'

import { DateTime } from "luxon"
import axios from 'axios'

import { SessionContext } from '../../context/SessionContext'

const initialState = {
  dataSource : [],
  index: 0,
  totalMuestra: 0,
  verificados: 0,
  aprobados: 0,
  rechazados: 0,
  porcAprobados: 0,
  porcRechazados: 0,
  porcVerificados: 0,
  cargandoImagenes: false
}

const reducer = (state, action) => {
  let _aprobados = state.aprobados,
    _rechazados = state.rechazados,
    _verificados = state.verificados,
    _index = state.index

  switch (action.type) {
    case 'UPDATEDATASOURCE':
      return {
        ...state,
        dataSource: action.data,
        totalMuestra: action.data.length,
        verificados: 0,
        aprobados: 0,
        rechazados: 0,
        porcAprobados: 0,
        porcRechazados: 0,
        porcVerificados: 0,
        index: 0,
        cargandoImagenes: false
      }

    case 'APROBADO':
      state.verificados < state.totalMuestra && _aprobados++
      state.verificados < state.totalMuestra && _verificados++
      _index < state.totalMuestra -1 && _index++

      if (state.index === state.totalMuestra -1) {
        // Ultimo registro
        message.warn('Ha revisado todos los registros')
      }
        
      return {
        ...state,
        aprobados: _aprobados,
        porcAprobados: (_aprobados / state.totalMuestra).toFixed(4) * 100,
        verificados: _verificados,
        porcVerificados: _verificados === state.totalMuestra ? 100 : (_verificados / state.totalMuestra).toFixed(4) * 100,
        index: _index
      }

    case 'RECHAZADO':
      state.verificados < state.totalMuestra && _rechazados++
      state.verificados < state.totalMuestra && _verificados++
      _index < state.totalMuestra -1 && _index++

      if (state.index === state.totalMuestra -1) {
        // Ultimo registro
        message.warn('Ha revisado todos los registros')
      }
      
      return {
        ...state,
        rechazados: _rechazados,
        porcRechazados: (_rechazados / state.totalMuestra).toFixed(4) * 100,
        verificados: _verificados,
        porcVerificados: _verificados === state.totalMuestra ? 100 : (_verificados / state.totalMuestra).toFixed(4) * 100,
        index: _index
      }
      
    case 'OMITIR':
      state.verificados < state.totalMuestra && _verificados++
      _index < state.totalMuestra -1 && _index++

      if (state.index === state.totalMuestra -1) {
        // Ultimo registro
        message.warn('Ha llegado al fin de la muestra')
      }

      return {
        ...state,
        verificados: _verificados,
        porcVerificados: _verificados === state.totalMuestra ? 100 : (_verificados / state.totalMuestra).toFixed(4) * 100,
        index: _index
      }

      case 'INICIO_CARGA_IMAGENES':
        return {
          ...state,
          cargandoImagenes: true
        }

      case 'FIN_CARGA_IMAGENES':
        return {
          ...state,
          cargandoImagenes: false
        }

    default:
      return state
  }
}

const Auditoria = () => {
  const { url, usuario } = useContext(SessionContext)

  const [state, dispatch] = useReducer(reducer, initialState)
  
  const [cargando, setCargando] = useState(false)
  const [grabandoAuditoria, setGrabandoAuditoria] = useState(false)
  const [categorias, setCategorias] = useState([])
  const [filtroRangoEdad, setFiltroRangoEdad] = useState([20, 100])
  const [filtroRangoFechas, setFiltroRangoFechas] = useState([])
  const [filtroFallidos, setFiltroFallidos] = useState(6)
  const [filtroRegistros, setFiltroRegistros] = useState(null)
  const [filtroClase, setFiltroClase] = useState(null)
  const [filtroSeguimiento, setFiltroSeguimiento] = useState(false)
  const [filtroRangoScore, setFiltroRangoScore] = useState([0,100])

  const grabarAuditoria = (resultado) => {
    const pRenaperCall = state.dataSource[state.index].pCallRenaper,
      comentario = form.getFieldValue('comentario').length > 0 ? form.getFieldValue('comentario') : ''
    
    if (resultado === 'omite') {
      form.resetFields()
      dispatch({type:'OMITIR'})

    } else {
      setGrabandoAuditoria(true)

      axios
        .post(`${url}/renaper/audit?usuario=${usuario.cUsuario}&llamadas=${pRenaperCall}&accion=${resultado}&comentario=${comentario}`)
        .then(res => {
          if (res.data.success) {
            resultado === 'aprueba' ? dispatch({type:'APROBADO'}) : dispatch({type:'RECHAZADO'})
          } else {
            message.error('Error inesperado, por favor vuelva a grabar')
            console.error('[grabarAuditoria]', res)
          }
        })
        .finally(() => {
          setGrabandoAuditoria(false)
          form.resetFields()
        })
    }
  }
  const obtenerDatos = () => {
    let arrDatos = []

    const strRangoFechas = filtroRangoFechas?.length === 2 ? '&fecha_inicio=' + filtroRangoFechas[0].startOf('month').format('YYYY-MM-DD') + '&fecha_termino=' + filtroRangoFechas[1].endOf('month').format('YYYY-MM-DD') : '',
      strFallidos = filtroFallidos < 5 ? `&fallidos_min=${filtroFallidos}&fallidos_max=${filtroFallidos}` :
        filtroFallidos === 5 ? `&fallidos_min=${filtroFallidos}&fallidos_max=999` : '',
      strRegistros = filtroRegistros != null ? `&limite=${filtroRegistros}` : '',
      strClase = filtroClase != null ? `&clase=${filtroClase}` : '',
      strSeguimiento = filtroSeguimiento ? '&seguimiento=1' : '',
      strRangoScore = `&score_min=${filtroRangoScore[0]*100}&score_max=${filtroRangoScore[1]*100}`;

    setCargando(true)

    axios
      // No recibe los parametros como tales
      // .post(`${url}/prueba_vida_ult`, {
      //   usuario: usuario.cUsuario,
      //   no_audit: 1,
      //   edad_min: filtroRangoEdad[0],
      //   edad_max: filtroRangoEdad[1],
      //   fecha_inicio: filtroRangoFechas?.length === 2 ? filtroRangoFechas[0].startOf('month').format('YYYY-MM-DD') : null,
      //   fecha_termino: filtroRangoFechas?.length === 2 ? filtroRangoFechas[1].startOf('month').format('YYYY-MM-DD') : null,
      //   fallidos_min: filtroFallidos <= 5 ? filtroFallidos : null,
      //   fallidos_max: filtroFallidos < 5 ? filtroFallidos : filtroFallidos === 5 ? 999 : null,
      //   limite: filtroRegistros != null ? filtroRegistros : null,
      //   clase: filtroClase != null ? filtroClase : null,
      //   seguimiento: filtroSeguimiento ? '1' : null
      // })
      .post(`${url}/prueba_vida_ult?usuario=${usuario.cUsuario}&no_audit=1&edad_min=${filtroRangoEdad[0]}&edad_max=${filtroRangoEdad[1]}${strRangoFechas}${strFallidos}${strClase}${strRegistros}${strSeguimiento}${strRangoScore}`)
      .then(res => {
        if (res.data.success) {
          if (res.data.records.length === 0) {
            message.warn('No existen datos con los filtros aplicado. Por favor modifíquelos y vuelva a generar una nueva muestra')
          
          } else {
            arrDatos = res.data.records.map((rec, index) => (
              {
                  ...rec,
                  key: rec.pCallRenaper,
                  cBeneficiario: rec.cApellido + ', ' + rec.cNombre
              }
            ))
          }

          dispatch({type:'UPDATEDATASOURCE', data: arrDatos})

        } else {
          message.error(`Error inesperado: ${res.data.message}`)
          console.error('[obtenerUltimasPruebas]', res)
        }
      })
      .finally(() => {
        setCargando(false)
      })
  }
  
  const onFiltroClase = (value) => {
    setFiltroClase(value)
  }

  const onFiltroFallidos = (value) => {
    setFiltroFallidos(value)
  }

  const onFiltroRangoFechas = (fechas) => {
    setFiltroRangoFechas(fechas)
  }

  const onFiltroRangoEdad = (value) => {
    setFiltroRangoEdad(value)
  }

  const onFiltroRangoScore = (value) => {
    setFiltroRangoScore(value)
  }

  const onFiltroSeguimiento = e => {
    setFiltroSeguimiento(e.target.checked)
  }

  useEffect(() => {
    const source = axios.CancelToken.source()

    const obtenerClases = async() => {
      setCargando(true)

      await axios
        .get(`${url}/clase`)
        .then(res => {
          setCargando(false)

          if (res.data.success) {
            setCategorias(res.data.records)
          } 
        })
        .catch(err => {
          setCargando(false)
          message.error(`Error inesperado: ${err}`)
          console.error('[obtenerClases]', err)
      })
    }

    obtenerClases()

    return () => {
      source.cancel()
    }
  }, [url, setCargando])

  const marcasEdad = {
    20: '20',
    35: '35',
    50: '50',
    65: '65',
    80: '80',
    100: '100'
  }

  const marcasFallidos = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5+',
    6: 'Todos'
  }

  const marcasCoincidencia = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    99: '100%'
  }

  const { RangePicker } = DatePicker
  const { Option } = Select
  const { TextArea } = Input
  const { Title } = Typography
  const [form] = Form.useForm()

  return cargando ? 
    <div className="spin-wrapper">
      <Spin className="spin-cargando" size="large" tip="Cargando datos..."/>
    </div> : 
    <Fragment>
      <Row gutter={[0,8]}>
        <Col>
          <Space size="small">
            <span>Edad</span><Slider
              range={true} 
              marks={marcasEdad} 
              step={5}
              value={filtroRangoEdad}
              min={20}
              style={{margin: '0 10px', width: 200}}
              onChange={(value) => onFiltroRangoEdad(value)}
            />
            <Select
              showSearch
              placeholder='Clase Beneficiario'
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onFiltroClase}
              value={filtroClase}
              style={{
                width: 300
              }}
            >
              <Option value={null}>Todas las clases</Option>
              {categorias.map(cat => (
                <Option key={cat.pClase} value={cat.pClase}>{cat.cNombre} (cod.{cat.pClase})</Option>
              ))}
            </Select>
            <Divider type='vertical'/>
            <RangePicker 
                picker="month" 
                style={{ width: 300 }}
                placeholder={["Ult. Prueba Inicio", "Ult. Prueba Fin"]}
                format={'MM/YYYY'}
                disabledDate={(currentDate) => {
                  return DateTime.local() < currentDate 
                }}      
                value={filtroRangoFechas}
                onChange={(date, strDate) => onFiltroRangoFechas(date)}
            />
            <span>Intentos Fallidos</span><Slider
              range={false} 
              marks={marcasFallidos} 
              step={1}
              value={filtroFallidos}
              min={0}
              max={6}
              defaultValue={6}
              style={{margin: '0 10px', width: 200}}
              onChange={(value) => onFiltroFallidos(value)}
            />
            <span>Coincidencia</span><Slider 
                range={true} 
                marks={marcasCoincidencia} 
                step={10}
                value={filtroRangoScore}
                style={{margin: '0 10px', width: 200}}
                onChange={(value) => onFiltroRangoScore(value)}
            />
            <Checkbox 
              checked={filtroSeguimiento} 
              onChange={onFiltroSeguimiento}>Seguimiento</Checkbox>
          </Space>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={[0,16]} justify="end">
        <Space size="small">
          <InputNumber 
            addonBefore='Total' 
            min={1} 
            onChange={(value) => {
              setFiltroRegistros(value)
            }}
          />
          <Tooltip placement="top" title="Aplicar Filtros">
              <Button 
                  type='primary'
                  onClick={obtenerDatos}
              >Generar Muestra</Button>
          </Tooltip>
        </Space>
      </Row>
      <Divider/>
      <Row gutter={[0,16]} >
        { state.dataSource.length === 0 ? 
          <Empty className='empty-text' description='Genere una muestra con los filtros disponibles'/> :
          <Fragment>
            <Col span={3}>
              <Space direction='vertical' size={'small'}>
                <Card>
                  <Statistic
                    title="Total Muestra"
                    value={`${state.totalMuestra} registros`}
                    precision={0}
                    valueStyle={{ color: '#40a9ff' }}
                  />
                </Card>
                <Card>
                  <Tooltip title={`${state.aprobados} aprobados`}>
                    <Progress percent={state.porcAprobados} status='success'/>
                  </Tooltip>
                  <Tooltip title={`${state.rechazados} rechazados`}>
                    <Progress percent={state.porcRechazados} status='exception' />
                  </Tooltip>
                  <Tooltip title={`${state.verificados} verificados`}>
                    <Progress percent={state.porcVerificados.toFixed(1)} type="circle" />
                  </Tooltip>
                </Card>
              </Space>
            </Col>
            <Col span={20}>
              { grabandoAuditoria ? 
                <div className="spin-wrapper">
                  <Spin className="spin-cargando" size="large" tip="Grabando..."/>
                </div> : 
                <Card
                  title={
                    <Title level={4}>{state.dataSource[state.index].cBeneficiario}{` (${state.dataSource[state.index].nEdad} años)`}</Title>
                  }
                  style={{
                    width: '100%', height: '100%'
                  }}
                >
                  <Row style={{height: 400}}>
                    <Col span={14}>
                      <Image.PreviewGroup>
                        <Space size={10}>
                          { state.dataSource[state.index].imagenes.map((item, idx, arr) => {
                              return <Image
                                key={idx}
                                width={200}
                                src={`${url}/renaper_image?id_llamada=${state.dataSource[state.index].pCallRenaper}&perfil=${item.cPerfil}`}
                                placeholder={<Skeleton.Image/>}
                              />
                            })
                          }
                        </Space>
                      </Image.PreviewGroup> 
                    </Col>
                    <Col span={10}>
                      <Form
                        form={form}
                        labelCol={{ span: 24 }}
                        layout="vertical"
                        requiredMark={'optional'}
                        initialValues={{ comentario:'' }}
                      >
                        <Form.Item 
                            label="Cometario"
                            name="comentario"
                        >
                            <TextArea rows={4} maxLength={1000} />
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                  <Row gutter={[8,40]}>
                    <Descriptions
                      bordered
                      size='small'
                    >
                      <Descriptions.Item label='Fecha'>{DateTime.fromSQL(state.dataSource[state.index].tCreacion).toFormat('dd/MM/yyyy HH:mm')}hs</Descriptions.Item>
                      <Descriptions.Item label='Coincidencia'><NumberFormat value={state.dataSource[state.index].nScore/100} displayType={'text'} decimalSeparator=',' decimalScale={2} suffix={'%'} /></Descriptions.Item>
                      <Descriptions.Item label='Fallidos'>{state.dataSource[state.index].nFallidos}</Descriptions.Item>
                      <Descriptions.Item label='Vencimiento'>{DateTime.fromSQL(state.dataSource[state.index].dSiguientePrueba).toFormat('dd/MM/yyyy')}</Descriptions.Item>
                    </Descriptions>
                  </Row>
                  <Row justify='end'>
                    <Space size={'middle'}> 
                      <Button type='primary' icon={<RightCircleOutlined/>} onClick={() => { grabarAuditoria('omite') }}>Omitir</Button>
                      <Button type='primary' danger icon={<CloseCircleOutlined/>} onClick={() => { grabarAuditoria('rechaza') }}>Rechazar</Button>
                      <Button type='primary' icon={<CheckCircleOutlined/>} style={{ background: "#8BC34E", borderColor: "#8BC34E" }} onClick={() => { grabarAuditoria('aprueba') }}>Aprobar</Button>
                    </Space>
                  </Row>
                </Card>
              }
            </Col>
          </Fragment>
        }
      </Row>
    </Fragment>
};

export default Auditoria;
