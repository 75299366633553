import React, { useEffect, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'

import { DateTime } from "luxon"

import './App.less';

import { Layout, ConfigProvider } from 'antd'

import esES from 'antd/lib/locale/es_ES'

import { SessionContext } from './context/SessionContext'
import PruebaVidaProvider from './context/PruebaVidaContext'

import Login from './components/auth/Login'
import MainHeader from './components/layouts/MainHeader'
import PruebaVida from './components/main/PruebaVida'
import Dashboard from './components/main/Dashboard'
import Parametros from './components/main/Parametros'
import Auditoria from './components/main/Auditoria'
import Bloqueados from './components/main/Bloqueados'

const { Header, Content, Footer } = Layout

function App() {
  const { usuario, setUsuario, signedIn, setSignedIn } = useContext(SessionContext)

  useEffect(() => {
    setUsuario(JSON.parse(window.localStorage.getItem('usuario')))
  }, [setUsuario])

  useEffect(() => {
    const resultado = !usuario ? false : DateTime.fromSQL(usuario.expires_date) > DateTime.now()
    usuario && window.localStorage.setItem('usuario', JSON.stringify(usuario))

    setSignedIn(resultado)

  }, [usuario, setSignedIn])

  return (
    <ConfigProvider locale={esES}>
      { signedIn ? (
        <Layout className="main-layout">
          <Header>
            <MainHeader/>
          </Header>
          <Content>
              <Routes>
                  <Route path='/' element={
                    <PruebaVidaProvider>
                      <PruebaVida />
                    </PruebaVidaProvider>
                  }/>
                  <Route path='/auditoria' element={
                    <Auditoria />
                  }/>
                  <Route path='/bloqueados' element={
                    <Bloqueados />
                  }/>
                  <Route path='/indicadores' element={
                    <Dashboard />
                  }/>
                  <Route path='/parametros' element={
                    <Parametros />
                  }/>
              </Routes>
          </Content>
          <Footer><a className="link" href='https://www.compustrom.com/' target="_blank" rel="noreferrer">Compustrom ©2022</a></Footer>
        </Layout>
      ) : <Login /> }
    </ConfigProvider>
  )
}

export default App;
