import React, { createContext, useState } from 'react'

export const PruebaVidaContext = createContext()

const PruebaVidaProvider = (props) => {
    const [beneficiario, setBeneficiario] = useState(null)
    const [isModalHistorialVisible, setIsModalHistorialVisible] = useState(false)

    return (
        <PruebaVidaContext.Provider
            value= {{
                beneficiario,
                setBeneficiario,
                isModalHistorialVisible,
                setIsModalHistorialVisible,
            }}
        >
            {props.children}
        </PruebaVidaContext.Provider>
    )
}

export default PruebaVidaProvider;